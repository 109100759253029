import React from "react";
import styled from "styled-components";
import { Box, BoxProps } from "rebass/styled-components";

const StyledBox = styled(Box)`
`;

// ACTUAL COMPONENT!
const Main = (props: BoxProps) => (
  <StyledBox
    {...(props as any)}
    sx={{
      borderTopRightRadius: "largest",
      borderBottomLeftRadius: "largest",
      minHeight: [
        "calc(100vh - 117px)",
        "calc(100vh - 223px)",
        "calc(65vh - 277px)",
      ],
      maxWidth: "1300px",
      margin: "0 auto",
      ...props.sx,
    }}
  >
    {props.children}
  </StyledBox>
);

Main.defaultProps = {
  bg: "primary",
};

export default Main;
