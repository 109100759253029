import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Main from '../components/main';
import { Box, Flex, Link } from 'rebass/styled-components';
import { H1 } from '../components/headers';
import Text from '../components/text';
import * as queryString from 'query-string';
import { PageProps } from 'gatsby';

const Services = ({ location }: PageProps) => {
  let { service, pharmacy } = queryString.parse(location.search);

  const [pharmacyParam, setPharmacyParam] = useState('');
  const [serviceParam, setServiceParam] = useState('');

  useEffect(() =>{
    if(pharmacy && service){
      setPharmacyParam('&pharmacy='+ pharmacy);
      setServiceParam('&service='+ service);
    }
  },[pharmacy, service]);

  return (
    <>
      <SEO title="Book a Pharmacy Service" />
      <Layout>
        <Main p={[3, 5, 5]} mb={4}>
          <Flex flexWrap="wrap">
            <Box width={1}>
              <H1 mb={1} color="white">
                Book a pharmacy service
              </H1>
            </Box>
            <Box width={1}>
              <Text color="white" fontSize={2} fontWeight="regular" my={3}>
                Search for a branch using your postcode and click a pharmacy on the map to book a service.
              </Text>
            </Box>
            <Box width={1}>
              <iframe
                src={`${process.env.GATSBY_BOOKING_SERVICE_IFRAME_URL}?labelColour=white&owned=false&platform=landing${pharmacyParam}${serviceParam}`}
                style={{ border: '0px solid white', width: '100%' }}
                name="intelligenceWidgetIframe"
                frameBorder="0px"
                height="1020px"
                marginHeight={0}
                marginWidth={0}
                scrolling="auto"
              ></iframe>
            </Box>
          </Flex>
        </Main>
      </Layout>
    </>
  );
};

export default Services;
