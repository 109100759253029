import React from "react";
import { Heading, HeadingProps } from "rebass/styled-components";

export const H1 = (props: HeadingProps) => (
  <Heading as="h1" fontSize={[3, 4, 5]} {...(props as any)}>
    {props.children}
  </Heading>
);

export const H2 = (props: HeadingProps) => (
  <Heading as="h2" fontSize={[2, 3, 4]} {...(props as any)}>
    {props.children}
  </Heading>
);

export const H3 = (props: HeadingProps) => (
  <Heading as="h3" fontSize={[1, 2, 3]} fontWeight="bold" {...(props as any)}>
    {props.children}
  </Heading>
);
